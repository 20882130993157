@import url('https://fonts.googleapis.com/css2?family=Lato:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@700&display=swap');


* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.main_wrapper {
    width: 100%;
    height: 100%;
}
.main_container {
    width: 100%;
}

/*top*/

.top {
    width: 100%;
    position: relative;
    margin-bottom: 80px;
    /*padding-bottom: 20px;*/
}
.header_top_wrapper {
    background-image: url("../img/our_work_speaks_background.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
}
.top_wrapper {
    width: 100%;
    max-width: 1170px;
    margin: 0 auto;
    position: relative;
    z-index: 9;
}

.top_items_wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
}



.top_item_images_box {
    width: 100%;
    max-width: 583px;
    height: 480px;
    position: relative;
    background-image: url("../../assets/img/top_img1.png");
    border-radius: 0 0 305px 0;
    overflow: hidden;
    background-position-y: -1px;
    background-size: 96%;
    background-repeat: no-repeat;
    bottom: 0;
}

.top_item_images_box img {
    width: 100%;
    display: block;
    max-width: 500px;
    position: absolute;
    bottom: 0;
    right: -64px;
}
.top_item_info_box {
    width: 100%;
    max-width: 585px;
    padding-top: 80px;
    /* flex: 1; */
}

.top_get_consultation_btn {
    cursor: pointer;
    outline: none;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #0092e4;
    font-family: 'Lato', sans-serif;
    font-size: 16px;
    font-weight: 700;
    padding: 13px 28px;
    color: #ffffff;
    border-radius: 30px;
    margin-right: 20px;
}
.top_get_consultation_btn:hover {
    text-decoration: underline;
}
.top_item_info_box_main_title {
    font-family: 'Raleway', sans-serif;
    text-transform: uppercase;
    font-size: 34px;
    font-weight: 600;
    line-height: 50px;
    margin-bottom: 20px;
    color: #000000;
}

.top_item_info_box_info1 {
    font-family: 'Raleway', sans-serif;
    font-size: 18px;
    font-weight: 500;
    line-height: 22px;
    color: #828282;
    margin-bottom: 15px;
    min-height: 80px;
    width: 80%
}

.top_item_info_social_link {
    width: 40px;
    height: 40px;
    cursor: pointer;
    outline: none;
    display: flex;
}
.top_item_info_social_link:first-child {
    width: 35px;
    height: 35px;
}

.top_item_info_social_link_svg_parent {
    width: 35px;
    height: 35px;
    background: #FF6501;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 2px;
}


.top_item_info_social_link:nth-child(2) img {
    width: 40px;
}
.top_item_info_social_link:nth-child(3) img {
    width: 40px;
}

.top_item_info_name_position_social_links_main_wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 42px;
}

.top_item_info_social_links_wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 160px;
}

.top_item_info_name {
    font-family: 'Raleway', sans-serif;
    font-size: 18px;
    font-weight: 700;
    line-height: 22px;
    color: #333333;
    margin-bottom: 8px;
    /*text-align: center;*/
}
.top_item_info_name2 {
    font-family: 'Raleway', sans-serif;
    font-size: 18px;
    color: #828282;
    font-weight: 400;
    line-height: 22px;
    /*text-align: center;*/

}
.top_item_info_name_position_box {
    /*max-width: 310px;*/
    max-width: 209px;
    width: 100%;
    margin-right: 20px;
}

.top_get_consultation_btn_phone_link_wrapper {
    display: flex;
    align-items: center;
}

.top_phone_link {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    text-align: center;
    text-decoration: none;
    font-family: "Lato", sans-serif;
    font-size: 16px;
    font-weight: 400;
    padding: 10px 18px;
    color: #ff5e00;
    border: 1px solid #ff5e00;
    border-radius: 30px;
    box-sizing: border-box;
    overflow: hidden;
    outline: 0;
    cursor: pointer;
    user-select: none;
    outline: none;
    margin-right: 15px;
}

.top_phone_link:hover {
    text-decoration: underline;
}

.top_img_main {
    position: absolute;
    left: -218px;
    /* background-position-x: calc(50% - 740px); */
    /*z-index: -1;*/
    /*z-index: -1;*/
    animation-name: pattern_welcome;
    animation-duration: 3s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    -moz-animation-iteration-count: infinite;
    -webkit-animation-iteration-count: infinite;
    /*margin-top: 150px;*/
    /*bottom: 0;*/
}


/*services*/
.services {
    width: 100%;
    margin-bottom: 60px;
}
.services_wrapper {
    width: 100%;
    max-width: 1170px;
    margin: 0 auto;
}

.services_title {
    font-family: 'Raleway', sans-serif;
    text-transform: uppercase;
    font-size: 33px;
    font-weight: 400;
    line-height: 38px;
    margin-bottom: 50px;
    color: #1E272E;
    text-align: center;
}
.services_title span {
    color: #FF6501;
}

.services_items_wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.services_item {
    background: white;
    border-radius: 27px;
    box-shadow: 0px 4px 22px rgba(0, 0, 0, 0.3);
    box-sizing: border-box;
    width: 31.5%;
    padding: 30px;
    min-height: 350px;
    margin-bottom: 30px;
}

.services_item_img {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}

.services_item_title {
    font-family: 'Raleway', sans-serif;
    /*min-height: 48px;*/
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    margin-bottom: 10px;
    color: #1E272E;
    text-align: center;
}

.services_item_info {
    font-family: 'Raleway', sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    color: #828282;
}


/*our_mobile_applications*/
.our_mobile_applications {
    width: 100%;
    margin-bottom: 80px;
}
#mobile_applications {
    /*background-image: url("../img/back_img2.png");*/
    /*background-repeat: no-repeat;*/
    /*background-size: 540px;*/
    /*background-position-x: calc(50% + 690px);*/
    /*background-position-y: 0;*/
    /*background-repeat: no-repeat;*/
    /*z-index: 1;*/
    /*animation-name: pattern_seoresults;*/
    /*animation-duration: 3s;*/
    /*animation-timing-function: ease-in-out;*/
    /*animation-iteration-count: infinite;*/
    /*-moz-animation-iteration-count: infinite;*/
    /*-webkit-animation-iteration-count: infinite;*/
}

#our_team {
    background-image: url("../img/back_img2.png");
    background-repeat: no-repeat;
    background-size: 540px;
    background-position-x: calc(50% + 690px);
    background-position-y: 0;
    background-repeat: no-repeat;
    z-index: 1;
    animation-name: pattern_seoresults;
    animation-duration: 3s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    -moz-animation-iteration-count: infinite;
    -webkit-animation-iteration-count: infinite;
}

@media (min-width: 1031px) and (max-width: 1270px) {
    #mobile_applications {
        background-position-x: calc(50% + 580px) !important

    }
}
@media (min-width: 951px) and (max-width: 1030px) {
    #mobile_applications {
        background-position-x: calc(50% + 470px) !important

    }
}
@media (min-width: 769px) and (max-width: 950px) {
    #mobile_applications {
        background-position-x: calc(50% + 370px) !important

    }
}
@media (min-width: 426px) and (max-width: 768px) {
    #mobile_applications {
        background-position-x: calc(50% + 270px) !important

    }
}
@media (max-width: 425px) {
    #mobile_applications {
        background-position-x: calc(50% + 170px) !important

    }
}
@keyframes pattern_seoresults {
    0% {
        background-position-y: 0px;
    }
    50% {
        background-position-y: 30px;
    }
    100% {
        background-position-y: 0px;
    }
}
.our_mobile_applications_wrapper {
    width: 100%;
    /*max-width: 1170px;*/
    margin: 0 auto;
}
.our_mobile_applications_title {
    margin-bottom: 20px;
    font-family: 'Raleway', sans-serif;
    text-transform: uppercase;
    font-size: 33px;
    font-weight: 700;
    line-height: 38px;
    color: #1E272E;
    text-align: center;
}

.our_mobile_applications_wrapper .our_team_info {
    margin-bottom: 50px;

}

.our_mobile_applications_items_wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}


.our_mobile_applications_item {
    background: #fff;
    /*border: 1px solid silver;*/
    /*border-radius: 20px;*/
    /*margin-bottom: 30px;*/
    /*padding: 1px;*/
    /*width: 49%;*/
    width: 50%;
    overflow: hidden;
    cursor: pointer;
    position: relative;
}
.our_mobile_applications_item_img {
    height: 400px;
    /*height: 318px;*/
    /*height: 243px;*/
    width: 100%;
    overflow: hidden;
}
.our_mobile_applications_item_img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: 0.5s;
}
.our_mobile_applications_item_name {
    color: #ee5a24;
    font-family: Raleway,sans-serif;
    font-size: 20px;
    font-weight: 500;
    /* margin-bottom: 30px; */
    text-transform: uppercase;
    padding: 40px;

    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: #00000057;
    color: white;
    font-size: 36px;
    font-weight: bold;
}

/*submit_your_application*/
.submit_your_application {
    width: 100%;
}
.submit_your_application_wrapper {
    width: 100%;
    background: radial-gradient(#0092e4, #004870);
    padding: 30px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    height: 100%;
    min-height: 470px;
}
.submit_your_application_img {
    width: 100%;
    max-width: 583px;
    height: 480px;
    position: absolute;
    bottom: 0;
    right: 0;
}

.submit_your_application_img img {
    width: 100%;
    height: 100%;
}

.submit_your_application_info_item {
    width: 100%;
    max-width: 880px;
    padding-left: 100px;
    padding-top: 50px;
    position: relative;
    z-index: 999;
}

.submit_your_application_info_item_title {
    font-family: 'Raleway', sans-serif;
    font-weight: 400;
    font-size: 32px;
    margin-bottom: 20px;
    text-transform: uppercase;
    width: 100%;
    color: #ffffff;
}

.submit_your_application_info_item_btn {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Lato", sans-serif;
    font-size: 16px;
    font-weight: 700;
    padding: 13px 28px;
    color: #ffffff;
    border: 1px solid #fff;
    border-radius: 30px;
    box-sizing: border-box;
    overflow: hidden;
    outline: 0;
    cursor: pointer;
    background: #ff6a38;
}

.submit_your_application_wrapper_img {
    position: absolute;
    left: 0;
    bottom: 0;
}

/*our_team*/

.our_team {
    width: 100%;
    margin-bottom: 60px;
}
.our_team_wrapper {
    width: 100%;
    max-width: 1170px;
    margin: 0 auto;
}

.our_team_items_wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}
.our_team_item {
    background: white;
    border-radius: 27px;
    box-shadow: 0px 4px 22px rgba(0, 0, 0, 0.3);
    box-sizing: border-box;
    width: 24%;
    padding: 30px;
    min-height: 329px;
    margin-bottom: 30px;
}
.our_team_item_img {
    width: 140px;
    height: 140px;
    margin: 0 auto;
    margin-bottom: 25px;
    /*border-radius: 100%;*/
    overflow: hidden;
    background-image: url("../img/our_team_img4.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 50%;
    position: relative;
}

.our_team_item_img img {
    display: block;
    max-width: unset;
    position: relative;
}
.our_team_title_info_wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 50px;
}

.our_team_title {
    font-family: 'Raleway', sans-serif;
    text-transform: uppercase;
    font-size: 36px;
    font-weight: 400;
    margin-bottom: 20px;
    color: #1E272E;
    text-align: center;
}

.our_team_info {
    font-family: 'Raleway', sans-serif;
    font-size: 16px;
    font-weight: 400;
    color: #828282;
    text-align: center;
    width: 100%;
    max-width: 660px;
    margin: 0 auto;
}

.our_team_item_title {
    font-family: 'Raleway', sans-serif;
    font-size: 20px;
    text-align: center;
    color: #1E272E;
    font-weight: 700;
    width: 125px;
    margin: 0 auto;
    margin-bottom: 14px;
}

.our_team_item_position {
    font-family: 'Raleway', sans-serif;
    color: #828282;
    font-weight: 400;
    font-size: 16px;
    text-align: center;
}

/*join_our_team*/
.join_our_team {
    width: 100%;
    background: radial-gradient(#0092e4, #004870);
    padding: 105px 0;
    height: 470px;
    /*min-height: 371px;*/
}
.join_our_team_wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
}

.join_our_team_item_img img {
    width: 100%;
    height: 100%;
}

.join_our_team_item_img {
    max-width: 448px;
    width: 100%;
    height: 530px;
    position: absolute;
    right: 0;
    top: -93px;
    z-index: 9;
}
.join_our_team_info_item {
    width: 100%;
    max-width: 1170px;
    margin: 0 auto;
    padding-top: 85px;
    position: relative;
    z-index: 9;
}

.join_our_team_info_item_title {
    font-family: "Lato", sans-serif;
    font-weight: 700;
    margin-bottom: 30px;
    text-transform: uppercase;
    font-size: 36px;
    color: #ffffff;
    max-width: 836px;
    width: 100%;
}

.join_our_team_wrapper_img {
    position: absolute;
    top: -33px;
    left: 0;
}

.join_our_team_info_item_btn {
    background: #ff6a38;
    cursor: pointer;
    outline: none;
    width: 100%;
    max-width: 132px;
    border: 1px solid #fff;
    border-radius: 30px;
    box-sizing: border-box;
    font-family: "Lato", sans-serif;
    font-weight: 700;
    color: #ffffff;
    font-size: 16px;
    padding: 13px 23px;
}

/*map*/

.map {
    width: 100%;
    background-color: #F2F2F2;
    /*padding-top: 30px;*/
}

.map_wrapper {
    width: 100%;
    max-width: 1170px;
    margin: 0 auto;
}
.map_wrapper iframe {
    width: 100%;
    height: 300px;
    margin-top: 34px;
    border: none;
}


@media (min-width: 476px) and (max-width: 1200px) {
    .our_team_wrapper {
        max-width: unset!important;
        width: 95% !important;
    }
    .top_wrapper {
        max-width: unset!important;
        width: 90% !important;
    }
    .services_wrapper {
        max-width: unset!important;
        width: 95% !important;
    }
    .our_mobile_applications_wrapper {
        max-width: unset!important;
        width: 95% !important;
    }
}
@media  (max-width: 476px) {
    .our_team_wrapper {
        max-width: unset!important;
        width: 90% !important;
    }
    .top_wrapper {
        max-width: unset!important;
        width: 90% !important;
    }
    .services_wrapper {
        max-width: unset!important;
        width: 90% !important;
    }
    .our_mobile_applications_wrapper {
        max-width: unset!important;
        width: 90% !important;
    }
}

@media (min-width: 491px) and (max-width: 950px) {

    .services_item {
        width: 48% !important;
    }
    .top_item_images_box {
        margin-bottom: 70px;
    }
    .top_items_wrapper {
        flex-direction: column-reverse;
        justify-content: center!important;
        align-items: center!important;
    }
    .top {
        padding-bottom: 80px;
    }
    .top_item_info_box {
        width: 100%;
         max-width: unset!important;
        padding-top: 80px;
        /* flex: 1; */
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
    }
    .top_item_info_box_main_title {
        text-align: center;
    }
   .top_item_info_box_info1 {
       text-align: center;
       margin-bottom: 30px!important;
   }
}
@media (min-width: 476px) and (max-width: 491px) {
    .services_item {
        width: 48% !important;
    }
}
@media  (max-width: 476px) {
    .services_item {
        width: 100% !important;
    }
}
@media (min-width: 410px) and (max-width: 491px) {
    .top_item_images_box {
        margin-bottom: 60px;
    }
    .top_items_wrapper {
        flex-direction: column-reverse;
        justify-content: center!important;
        align-items: center!important;
    }
    .top {
        padding-bottom: 80px;
    }
    .top_item_info_box {
        width: 100%;
         max-width: unset!important;
        padding-top: 50px!important;
        /* flex: 1; */
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
    }

   .top_item_info_box_info1 {
       text-align: center;
       margin-bottom: 30px!important;
   }
    .top_item_info_name_position_box {
         max-width: unset!important;
        width: 100%;
        /* margin: 0 auto; */
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin-bottom: 19px;
    }
    .top_item_info_name_position_social_links_main_wrapper {
        flex-wrap: wrap;
    }
    .top_item_info_social_links_wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 160px;
        margin: 0 auto;

    }
    .top_get_consultation_btn {
        padding: 13px 22px!important;
        font-size: 14px!important;
        margin-right: 10px!important;
    }
    .top_phone_link {
        padding: 13px 22px!important;
        font-size: 14px!important;
    }
}
@media (min-width: 375px) and (max-width: 410px) {
    .top_item_images_box {
        margin-bottom: 50px;
    }
    .top_items_wrapper {
        flex-direction: column-reverse;
        justify-content: center!important;
        align-items: center!important;
    }
    .top {
        padding-bottom: 60px;
    }
    .top_item_info_box {
        width: 100%;
         max-width: unset!important;
        padding-top: 30px!important;
        /* flex: 1; */
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
    }
    /*.top_item_info_box_main_title {*/
    /*    text-align: center;*/
    /*    font-size: 28px!important;*/
    /*    line-height: 40px!important;*/
    /*}*/
    /*.services_title {*/
    /*    text-align: center;*/
    /*    font-size: 28px!important;*/
    /*    line-height: 40px!important;*/
    /*}*/
    /*.our_mobile_applications_title {*/
    /*    font-size: 28px!important;*/
    /*}*/
   .top_item_info_box_info1 {
       text-align: center;
       margin-bottom: 30px!important;
   }
    .top_item_info_name_position_box {
         max-width: unset!important;
        width: 100%;
        /* margin: 0 auto; */
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin-bottom: 19px;
    }
    .top_item_info_name_position_social_links_main_wrapper {
        flex-wrap: wrap;
    }
    .top_item_info_social_links_wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 160px;
        margin: 0 auto;

    }
    .top_get_consultation_btn {
        padding: 13px 22px!important;
        font-size: 14px!important;
        margin-right: unset!important;
        margin-bottom: 15px;
        width: 100%;
    }
    .top_phone_link {
        padding: 13px 22px!important;
        font-size: 14px!important;
    }

    .top_get_consultation_btn_phone_link_wrapper {
        flex-wrap: wrap;
    }
}
@media (min-width: 351px) and  (max-width: 375px) {
    .top_item_images_box {
        margin-bottom: 45px;
    }
    .top_items_wrapper {
        flex-direction: column-reverse;
        justify-content: center!important;
        align-items: center!important;
    }
    .top {
        padding-bottom: 60px;
    }
    .top_item_info_box {
        width: 100%;
         max-width: unset!important;
        padding-top: 30px!important;
        /* flex: 1; */
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
    }
    /*.top_item_info_box_main_title {*/
    /*    text-align: center;*/
    /*    font-size: 26px!important;*/
    /*    line-height: 36px!important;*/
    /*}*/
    /*.our_mobile_applications_title {*/
    /*    font-size: 26px!important;*/
    /*}*/
    /*.services_title {*/
    /*    text-align: center;*/
    /*    font-size: 26px!important;*/
    /*    line-height: 36px!important;*/
    /*    margin-bottom: 30px!important;*/

    /*}*/
   .top_item_info_box_info1 {
       text-align: center;
       margin-bottom: 30px!important;
   }
    .top_item_info_name_position_box {
         max-width: unset!important;
        width: 100%;
        /* margin: 0 auto; */
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin-bottom: 19px;
    }
    .top_item_info_name_position_social_links_main_wrapper {
        flex-wrap: wrap;
    }
    .top_item_info_social_links_wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 160px;
        margin: 0 auto;

    }
    .top_get_consultation_btn {
        padding: 13px 22px!important;
        font-size: 14px!important;
        margin-right: unset!important;
        margin-bottom: 15px;
        width: 100%;
    }
    .top_phone_link {
        padding: 13px 22px!important;
        font-size: 14px!important;
    }

    .top_get_consultation_btn_phone_link_wrapper {
        flex-wrap: wrap;
    }
}
@media  (max-width: 351px) {
    .top_item_images_box {
        margin-bottom: 45px;
    }
    .top_items_wrapper {
        flex-direction: column-reverse;
        justify-content: center!important;
        align-items: center!important;
    }
    .top {
        padding-bottom: 50px;
    }
    .top_item_info_box {
        width: 100%;
         max-width: unset!important;
        padding-top: 30px!important;
        /* flex: 1; */
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
    }
    /*.top_item_info_box_main_title {*/
    /*    text-align: center;*/
    /*    font-size: 24px!important;*/
    /*    line-height: 36px!important;*/
    /*}*/
    /*.our_mobile_applications_title {*/
    /*    font-size: 24px!important;*/
    /*}*/
    /*.services_title {*/
    /*    text-align: center;*/
    /*    font-size: 24px!important;*/
    /*    line-height: 36px!important;*/
    /*    margin-bottom: 30px!important;*/

    /*}*/
   .top_item_info_box_info1 {
       text-align: center;
       margin-bottom: 30px!important;
   }
    .top_item_info_name_position_box {
         max-width: unset!important;
        width: 100%;
        /* margin: 0 auto; */
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin-bottom: 19px;
    }
    .top_item_info_name_position_social_links_main_wrapper {
        flex-wrap: wrap;
    }
    .top_item_info_social_links_wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 160px;
        margin: 0 auto;

    }
    .top_get_consultation_btn {
        padding: 13px 22px!important;
        font-size: 14px!important;
        margin-right: unset!important;
        margin-bottom: 15px;
        width: 100%;
    }
    .top_phone_link {
        padding: 13px 22px!important;
        font-size: 14px!important;
    }

    .top_get_consultation_btn_phone_link_wrapper {
        flex-wrap: wrap;
    }
}

/*@media (min-width: 491px) and (max-width: 2800px) {*/
/*    .top_item_info_social_links_wrapper {*/
/*        margin-left: 20px;*/
/*    }*/
/*}*/


@media (min-width: 769px) and (max-width: 1031px) {
    .top_item_info_box_main_title {
        text-align: center;
        font-size: 32px!important;
    }
    .services_title {
        text-align: center;
        font-size: 31px!important;
    }
    .our_mobile_applications_title {
        font-size: 31px!important;
    }
}
@media (min-width: 426px) and (max-width: 768px) {
    .top_item_info_box_main_title {
        text-align: center;
        font-size: 28px!important;
        line-height: unset!important;
    }
    .services_title {
        text-align: center;
        font-size: 28px!important;
        line-height: 40px!important;

    }
    .our_mobile_applications_title {
        font-size: 28px!important;
    }
}
@media (min-width: 351px) and (max-width: 425px) {
    .top_item_info_box_main_title {
        text-align: center;
        font-size: 26px!important;
        line-height: unset!important;
    }
    .services_title {
        text-align: center;
        font-size: 26px!important;
        line-height: 36px!important;
        margin-bottom: 30px!important;
    }
    .our_mobile_applications_title {
        font-size: 26px!important;
    }


}
@media  (max-width: 350px) {
    .top_item_info_box_main_title {
        text-align: center;
        font-size: 24px!important;
        line-height: unset!important;
    }
    .services_title {
        text-align: center;
        font-size: 24px!important;
        line-height: 36px!important;
        margin-bottom: 30px!important;
    }
    .our_mobile_applications_title {
        font-size: 24px!important;
    }


}


@media (min-width: 426px) and (max-width: 475px) {
    .top_item_images_box {
        height: 390px!important;
    }
    .our_mobile_applications {
        margin-bottom: 80px!important;
    }
}
@media (min-width: 376px) and (max-width: 426px) {
    .our_mobile_applications {
        margin-bottom: 60px!important;
    }
    .top {
        margin-bottom: 60px!important;
    }
    .top_item_images_box {
        height: 350px!important;
    }
}
@media (min-width: 366px) and (max-width: 376px) {
    .our_mobile_applications {
        margin-bottom: 60px!important;
    }
    .top {
        margin-bottom: 50px!important;
    }
    .top_item_images_box {
        height: 330px!important;
    }
}
@media (min-width: 342px) and (max-width: 366px) {
    .our_mobile_applications {
        margin-bottom: 60px!important;
    }
    .top {
        margin-bottom: 50px!important;
    }
    .top_item_images_box {
        height: 300px!important;
    }
    .top_item_images_box img {
        bottom: 9px!important;
        right: -16px!important;
    }
}
@media  (max-width: 342px) {
    .our_mobile_applications {
        margin-bottom: 60px!important;
    }
    .top {
        margin-bottom: 50px!important;
    }
    .top_item_images_box {
        height: 300px!important;
    }
    .top_item_images_box img {
        bottom: 20px!important;
        right: -18px!important;
    }
}


#top_item_images_box_mobile {
    display: none;
}
@media (max-width: 950px) {
    #top_item_images_box_mobile {
        display: block!important;
    }
    #top_item_images_box_desktop {
        display: none!important;
    }

    .top_img_main {
        margin-top: 70px!important;
    }
}


@media (min-width: 521px) and (max-width: 610px) {

    .services_item {
        padding: 15px!important;
        min-height: 335px!important;
    }
    .services_item_img img {
        width: 90px!important;
        height: 90px!important;
    }
    .services_item_title {
        font-size: 18px!important;
    }

}
@media (min-width: 476px) and (max-width: 521px) {
    .services_item {
        padding: 15px !important;
        min-height: 335px !important;
        border-radius: 20px!important;

    }

    .services_item_img img {
        width: 80px !important;
        height: 80px !important;
    }

    .services_item_title {
        font-size: 16px !important;
    }

    .services_item_info {
        font-size: 13px !important;

    }
}
@media (max-width: 476px) {
    .our_mobile_applications_item {
        width: 100% !important;
    }
    .services_item {
        padding: 15px !important;
        min-height: 290px !important;
        border-radius: 15px!important;
    }
    .our_mobile_applications_item_name {
        font-size: 22px!important;
    }

    .services_item_img img {
        width: 80px !important;
        height: 80px !important;
    }

    .services_item_title {
        font-size: 16px !important;
    }

    .services_item_info {
        font-size: 13px !important;

    }
    .services {
        margin-bottom: 40px!important;
    }
}

@media (min-width: 1001px) and (max-width: 1200px) {
    .submit_your_application_info_item {
        padding-left: 55px!important;
    }
    .submit_your_application_img {
        width: 100%;
        max-width: 530px!important;
        height: 430px!important;
    }
}
@media (min-width: 884px) and (max-width: 1001px) {
    .submit_your_application_info_item {
        padding-left: 45px!important;
    }
    .submit_your_application_img {
        width: 100%;
        max-width: 500px!important;
        height: 400px!important;
    }
    .submit_your_application_info_item {
        width: 100%;
        max-width: 500px!important;
    }
    .submit_your_application_info_item_title {
        font-size: 28px!important;
    }
}
@media (min-width: 771px) and (max-width: 884px) {
    .submit_your_application_info_item {
        padding-left: 35px!important;
    }
    .submit_your_application_img {
        width: 100%;
        max-width: 470px!important;
        height: 400px!important;
    }
    .submit_your_application_info_item {
        width: 100%;
        max-width: 400px!important;
    }
    .submit_your_application_info_item_title {
        font-size: 26px!important;
    }
}
@media (min-width: 551px) and (max-width: 770px) {

    .submit_your_application_img {
        width: 100%;
        max-width: 470px!important;
        height: 400px!important;
    }
    .submit_your_application_info_item {
        width: 100%;
        max-width: unset!important;
        padding-left: 12px!important;
        padding-right: 12px!important;
        position: absolute;
        top: 26px;
        padding-top: 15px!important;
    }
    .submit_your_application_wrapper {
        min-height: 600px!important;
    }
    .submit_your_application_info_item_title {
        font-size: 26px!important;
    }
    /*.submit_your_application {*/
    /*    margin-bottom: 70px!important;*/
    /*}*/
}
@media (min-width: 478px) and (max-width: 550px) {
    /*.submit_your_application {*/
    /*    margin-bottom: 60px!important;*/
    /*}*/

    .submit_your_application_img {
        width: 100%;
        max-width: 470px!important;
        height: 400px!important;
    }
    .submit_your_application_info_item {
        width: 100%;
        max-width: unset!important;
        padding-left: 12px!important;
        padding-right: 12px!important;
        position: absolute;
        top: 26px;
        padding-top: 15px!important;
    }
    .submit_your_application_wrapper {
        min-height: 650px!important;
    }
    .submit_your_application_info_item_title {
        font-size: 24px!important;
    }
}
@media (min-width: 425px) and (max-width: 477px) {

    /*.submit_your_application {*/
    /*    margin-bottom: 60px!important;*/
    /*}*/
    .submit_your_application_img {
        width: 100%;
        max-width: 430px!important;
        height: 370px!important;
    }
    .submit_your_application_info_item {
        width: 100%;
        max-width: unset!important;
        padding-left: 12px!important;
        padding-right: 12px!important;
        position: absolute;
        top: 26px;
        padding-top: 15px!important;
    }
    .submit_your_application_wrapper {
        min-height: 650px!important;
    }
    .submit_your_application_info_item_title {
        font-size: 24px!important;
    }
}
@media (min-width: 375px) and (max-width: 425px) {

    /*.submit_your_application {*/
    /*    margin-bottom: 50px!important;*/
    /*}*/
    .submit_your_application_img {
        width: 100%;
        max-width: 400px!important;
        height: 330px!important;
    }
    .submit_your_application_info_item {
        width: 100%;
        max-width: unset!important;
        padding-left: 12px!important;
        padding-right: 12px!important;
        position: absolute;
        top: 26px;
        padding-top: 15px!important;
    }
    .submit_your_application_wrapper {
        min-height: 591px!important;
    }
    .submit_your_application_info_item_title {
        font-size: 22px!important;
    }
}
@media (min-width: 345px) and (max-width: 375px) {

    /*.submit_your_application {*/
    /*    margin-bottom: 50px!important;*/
    /*}*/
    .submit_your_application_img {
        width: 100%;
        max-width: 370px!important;
        height: 300px!important;
    }
    .submit_your_application_info_item {
        width: 100%;
        max-width: unset!important;
        padding-left: 12px!important;
        padding-right: 12px!important;
        position: absolute;
        top: 26px;
        padding-top: 15px!important;
    }
    .submit_your_application_wrapper {
        min-height: 550px!important;
    }
    .submit_your_application_info_item_title {
        font-size: 20px!important;
    }
}
@media  (max-width: 345px) {

    /*.submit_your_application {*/
    /*    margin-bottom: 50px!important;*/
    /*}*/
    .submit_your_application_img {
        width: 100%;
        max-width: 340px!important;
        height: 270px!important;
    }
    .submit_your_application_info_item {
        width: 100%;
        max-width: unset!important;
        padding-left: 12px!important;
        padding-right: 12px!important;
        position: absolute;
        top: 26px;
        padding-top: 15px!important;
    }
    .submit_your_application_wrapper {
        min-height: 500px!important;
    }
    .submit_your_application_info_item_title {
        font-size: 18px!important;
    }
}

@media (min-width: 1001px) and (max-width: 1126px) {
    .submit_your_application_info_item {
        width: 100%;
        max-width: 600px!important;
    }
    .submit_your_application_info_item_title {
        font-size: 30px!important;
    }
}


@media (min-width: 768px) and (max-width: 1024px) {
    .our_team_item {
        width: 32% !important;
        margin-bottom: 22px!important;
    }
}
@media (min-width: 490px) and  (max-width: 768px) {
    .our_team_item {
        width: 48% !important;
        margin-bottom: 22px!important;
        padding: 30px 20px;
    }
}
@media  (max-width: 490px) {
    .our_team_item {
        width: 100% !important;
        margin-bottom: 22px!important;
        padding: 30px 20px;
    }
}

@media (min-width: 475px) and (max-width: 768px) {
    .our_team_title {
        font-size: 32px!important;
    }

    .our_team_title_info_wrapper {
        margin-bottom: 40px!important;
    }
}

@media (min-width: 426px) and  (max-width: 475px) {
    .our_team_title_info_wrapper {
        margin-bottom: 30px!important;
    }
    .our_team_title {
        font-size: 30px!important;
    }
    .our_team_info {
        font-size: 14px!important;
    }
}
@media (min-width: 376px) and  (max-width: 425px) {
    .our_team_info {
        font-size: 14px!important;
    }
    .our_team_title {
        font-size: 28px!important;
    }
    .our_team_title_info_wrapper {
        margin-bottom: 30px!important;
    }
}
@media (max-width: 375px) {
    .our_team_title_info_wrapper {
        margin-bottom: 30px!important;
    }
    .our_team_info {
        font-size: 14px!important;
    }
    .our_team_title {
        font-size: 26px!important;
    }
}

@media (min-width: 1024px) and (max-width: 1310px) {
    .join_our_team_info_item {
        max-width: unset!important;
        width: 90% !important;
        padding-top: 60px!important;
    }
    .join_our_team_info_item_title {
        max-width: unset!important;
        width: 60% !important;
    }
}
@media (min-width: 931px) and (max-width: 1024px) {
    .join_our_team_info_item {
        max-width: unset!important;
        width: 90% !important;
        padding-top: 40px!important;
    }
    .join_our_team_info_item_title {
        max-width: unset!important;
        width: 60% !important;
        font-size: 32px!important;
    }
}

@media (min-width: 769px) and (max-width: 931px) {
    .join_our_team_item_img {
        max-width: 400px!important;
        height: 470px!important;
    }
    .join_our_team {
        height: 430px!important;
    }
    .join_our_team_wrapper_img img {
        height: 340px;
    }
    .join_our_team_wrapper_img {
        top: -78px;
    }
    .join_our_team_info_item {
        max-width: unset!important;
        width: 90% !important;
        padding-top: unset!important;
        position: absolute;
        top: -57px;
        left: 22px;
    }
    .join_our_team_info_item_title {
        max-width: unset!important;
        width: 50% !important;
        font-size: 30px!important;
    }
}
@media (min-width: 507px) and (max-width: 768px) {
    .join_our_team_item_img {
        max-width: 400px!important;
        height: 470px!important;
        top: 22px!important;
        padding-top: 50px;
    }
    .join_our_team {
        height: 540px!important;
    }
    .join_our_team_wrapper_img img {
        height: 340px;
    }
    .join_our_team_wrapper_img {
        top: -78px;
    }
    .join_our_team_info_item {
        max-width: unset!important;
        width: 90% !important;
        padding-top: unset!important;
        position: absolute;
        top: -57px;
        left: 22px;
    }
    .join_our_team_info_item_title {
        max-width: unset!important;
        width: 100% !important;
        font-size: 28px!important;
    }
}
@media (min-width: 426px) and (max-width: 507px) {
    .join_our_team_item_img {
        max-width: 360px!important;
        height: 430px!important;
        top: 81px!important;
        padding-top: 50px;
    }
    .join_our_team {
        height: 540px!important;
        padding: 86px 0!important;
    }
    .join_our_team_wrapper_img img {
        height: 340px;
    }
    .join_our_team_wrapper_img {
        top: -78px;
    }
    .join_our_team_info_item {
        max-width: unset!important;
        width: 90% !important;
        padding-top: unset!important;
        position: absolute;
        top: -57px;
        left: 22px;
    }
    .join_our_team_info_item_title {
        max-width: unset!important;
        width: 100% !important;
        font-size: 26px!important;
        margin-bottom: 20px!important;
    }
}
@media (min-width: 376px) and (max-width: 426px) {
    .join_our_team_item_img {
        max-width: 360px!important;
        height: 430px!important;
        top: 81px!important;
        padding-top: 50px;
    }
    .join_our_team {
        height: 540px!important;
        padding: 86px 0!important;
    }
    .join_our_team_wrapper_img img {
        height: 340px;
    }
    .join_our_team_wrapper_img {
        top: -78px;
    }
    .join_our_team_info_item {
        max-width: unset!important;
        width: 90% !important;
        padding-top: unset!important;
        position: absolute;
        top: -57px;
        left: 22px;
    }
    .join_our_team_info_item_title {
        max-width: unset!important;
        width: 100% !important;
        font-size: 24px!important;
        margin-bottom: 20px!important;
    }
}
@media  (max-width: 376px) {
    .join_our_team_item_img {
        max-width: 320px!important;
        height: 400px!important;
        top: 100px!important;
        padding-top: 50px;
    }
    .join_our_team {
        height: 540px!important;
        padding: 86px 0!important;
    }
    .join_our_team_wrapper_img img {
        height: 320px;
    }
    .join_our_team_wrapper_img {
        top: -78px;
    }
    .join_our_team_info_item {
        max-width: unset!important;
        width: 90% !important;
        padding-top: unset!important;
        position: absolute;
        top: -57px;
        left: 22px;
    }
    .join_our_team_info_item_title {
        max-width: unset!important;
        width: 100% !important;
        font-size: 22px!important;
        margin-bottom: 20px!important;
    }
    .join_our_team_info_item_btn {
        padding: 11px 22px!important;
    }
}



@media (min-width: 951px) and (max-width: 3000px)  {
    @keyframes pattern_welcome {
        0% {
            /*background-position-y: calc(100% - 20px);*/
            bottom: 20px;
        }
        50% {
            /*background-position-y: calc(100% - 50px);*/
            bottom: 50px;
        }
        100% {
            /*background-position-y: calc(100% - 20px);*/
            bottom: 20px;
        }
    }

    .top_img_main {
        bottom: 0;
    }
}
@media  (max-width: 950px)  {
    @keyframes pattern_welcome {
        0% {
            /*background-position-y: calc(100% - 20px);*/
            top: 20px;
        }
        50% {
            /*background-position-y: calc(100% - 50px);*/
            top: 50px;
        }
        100% {
            /*background-position-y: calc(100% - 20px);*/
            top: 20px;
        }
    }

    .top_img_main {
        top: 0;
        left: -183px!important;
    }
}


@media (min-width: 951px) and (max-width: 1200px) {
    .top_img_main {
        left: -238px!important;
    }
}
/*@media  (max-width: 425px) {*/
/*    .top_img_main {*/
/*        top: 0;*/
/*        !*left: -238px!important;*!*/
/*    }*/
/*}*/

@media (max-width: 1031px) {
    /*.top_phone_link {*/
    /*    padding: 13px 20px!important;*/
    /*}*/
    .top_get_consultation_btn {
        padding: 13px 20px!important;
    }
}

@media (min-width: 951px) and (max-width: 1030px) {
    .top_item_info_social_link img {
        width: 30px!important;
    }
    }


@media (max-width: 600px) {
    .top_img_main img {
        height: 280px!important;
    }
}

.upButton {
    position: fixed;
    left: 20px;
    bottom: 20px;
    width: 60px;
    height: 60px;
    background-color: #FF6501;
    background-image: url("../svg/scroll_btn.svg");
    background-size: 14px;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 50%;
    overflow: hidden;
    outline: 0;
    opacity: .7;
    cursor: pointer;
    z-index: 9999999;
    border: none;
    outline: none;

}

@media (max-width: 378px) {
    .submit_your_application_wrapper_img img {
        width: 320px!important;
    }
}

@media (min-width: 476px) and (max-width: 768px) {
    .our_mobile_applications_item {
        width: 48% !important;
    }
}

/*@media (min-width: 411px) and (max-width: 3000px) {*/
/*    .top_item_info_social_links_wrapper {*/
/*        !*margin-left: 50px;*!*/
/*        margin-left: 0;*/
/*    }*/
/*}*/
@media  (max-width: 410px) {
    .top_item_info_social_links_wrapper {
        margin-top: 20px;
    }
}

.our_mobile_applications_item_name{
    top: -100%;
    left: -100%;
    /*transition: 0.1s;*/
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5), -2px -2px 4px rgba(255, 255, 255, 0.5);
}
.our_mobile_applications_item:hover .our_mobile_applications_item_img img {
    transform: scale(1.4);
}
.our_mobile_applications_item:hover .our_mobile_applications_item_name {
    top: 0;
    left: 0;
    z-index: 9;
}


.our_mobile_applications_item_name_text {

}
.our_mobile_applications_item_desc_text {
    font-size: 18px;
    margin-top: 12px;
    max-width: 500px;
}
.our_mobile_applications_item_more {
    display: inline-block;
    justify-content: center;
    align-items: center;
    position: relative;
    text-align: center;
    text-decoration: none;
    font-family: "Lato", sans-serif;
    font-size: 16px;
    font-weight: 400;
    padding: 10px 18px;
    color: white;
    border: 1px solid #ff5e00;
    border-radius: 30px;
    box-sizing: border-box;
    overflow: hidden;
    outline: 0;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    outline: none;
    margin-right: 15px;
    background: #ff5e00;
    margin-top: 29px;
}

.our_mobile_applications_item_more:hover{
    text-decoration: underline;
}

@media (max-width: 650px) {
    .show_get_consultation_popup_body_wrapper {
        flex-direction: column-reverse;
    }
    .show_get_consultation_popup_body_wrapper_right {
            display: none!important;
    }
    .show_get_consultation_popup_wrapper_parent {
        max-width: unset!important;
        width: 90% !important;
    }

}

@media (max-width: 491px) {
    .top_item_info_name2 {
        text-align: center!important;
    }
}

.success_popup {
    position: fixed; /* Stay in place */
    z-index: 999999; /* Sit on top */
    left: 0;
    /*right: -1000px;*/
    /*transition: 0.5s;*/
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background: rgba(40, 40, 56, 0.76);
    backdrop-filter: blur(2px);
}
.success_popup_parent{
    overflow: scroll;
    overflow-x: hidden;
    overflow-y: hidden;
    height: 100%;
    width: 100%;
    padding: 30px 30px 0px 30px;
}
.success_popup_wrapper {
    background: #F5F5F5;
    box-shadow: 0px 4px 4px 1px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    width: 100%;
    max-width: 778px;
    /*height: 100%;*/
    position: relative;
    margin: 50px auto;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 50px 25px 160px 25px;
}

.success_popup_title {
    font-family: 'Lato', sans-serif;
    font-size: 40px;
    font-weight: 700;
    color: #575757;
    text-align: center;
    margin-bottom: 20px;

}
.success_popup_info {
    font-family: 'Lato', sans-serif;
    font-size: 28px;
    font-weight: 400;
    color: #17171f;
    text-align: center;
}

.success_popup_close_btn {
    position: absolute;
    cursor: pointer;
    background: none;
    border: none;
    right: 30px;
    top: 30px;
}
.success_popup_logo{
    margin-bottom: 50px;
}

.main_whatsapp_telegram_links_wrapper {
    /* position: absolute; */
    position: fixed;
    right: 20px;
    bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.main_whatsapp_telegram_link {
    cursor: pointer;
    outline: none;
    display: flex;
    text-decoration: none;
}
