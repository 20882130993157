@import url('https://fonts.googleapis.com/css2?family=Raleway&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@700&display=swap');

*{
    margin: 0;
    padding:0;
    box-sizing: border-box;
}

.footer {
    width: 100%;
    background: #F2F2F2;
}
.footer_wrapper {
    width: 100%;
    max-width: 1170px;
    margin: 0 auto;
}

.footer_items_wrapper {
    width: 100%;
}

.footer_first_item {
    width: 100%;
    padding: 50px 0;
    margin-bottom: 30px;
    border-bottom: 1px solid #E0E0E0;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

}

.footer_first_item_social_link {
    width: 40px;
    height: 40px;
    display: flex;
    text-decoration: none;
    cursor: pointer;
    outline: none;
}
.footer_first_item_social_link:first-child {
    width: 35px;
    height: 35px;
}

.footer_first_item_social_link:last-child img  {
    width: 34px;
    position: relative;
    top: 3px;
    height: 40px;
}



.footer_first_item_social_link:nth-child(2) img  {
    width: 40px;
   height: 40px;
}

.footer_first_item_social_links_wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 170px;
    padding-top: 25px;

}

.footer_first_item_info_box_title {
    font-family: 'Raleway', sans-serif;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    margin-bottom: 20px;
    /*color: #333333;*/
    color: #FF6501;
}

.footer_first_item_info_box_main_info {
    font-family: 'Raleway', sans-serif;
    font-size: 14px;
    font-weight: 500;
    color: #828282;
    margin-bottom: 10px;
}


.footer_first_item_info_box_main_info2 {
    font-family: 'Raleway', sans-serif;
    font-size: 14px;
    font-weight: 500;
    color: #828282;
}

.footer_first_item_info_box2_address_details_info {
    font-family: 'Raleway', sans-serif;
    font-size: 14px;
    font-weight: 500;
    color: #828282;
    margin-bottom: 7px;
    display: flex;
    align-items: center;
}

.footer_first_item_info_box2_address_details_info a {
    cursor: pointer;
    outline: none;
    text-decoration: none;
    color: #FF6501!important;
    font-weight: 600!important;
    padding-left: 5px;
}

.footer_first_item_info_box {
    width: calc(55% - 23px);
    margin-right: 23px;
}

.footer_first_item_logo_social_links_wrapper {
    width: 20%;
}

.footer_first_item_info_box2 {
    width: 26%;
}

.footer_second_item_info {
    font-family: 'Raleway', sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    color: #828282;
}

.footer_second_item_links_wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 300px;
    width: 100%;
}

.footer_second_item_link {
    font-family: 'Raleway', sans-serif;
    text-decoration: underline;
    font-size: 14px;
    font-weight: 400;
    color: #828282;
    cursor: pointer;
}

.footer_second_item {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    padding: 40px 0;
}

.footer {
    width: 100%;
    background: #F2F2F2;
    position: relative;
    top: -4px;
}

/*media*/

@media (min-width: 426px) and (max-width: 1200px) {
    .footer_wrapper {
        max-width: unset!important;
        width: 95% !important;
    }
}
@media  (max-width: 426px) {
    .footer_wrapper {
        max-width: unset!important;
        width: 90% !important;
    }
}

@media (min-width: 851px) and (max-width: 1200px) {
    .footer_first_item_info_box {
        width: calc(55% - 63px) !important;
        margin-right: 10px!important;
    }
}

@media  (max-width: 951px) {
    .footer_logo_link img {
        width: 160px!important;
        height: 65px!important;
    }
}
@media (min-width: 852px) and (max-width: 1024px) {
    .footer_first_item_info_box2_address_details_info {
        flex-direction: column;
        align-items: unset!important;
    }
    .footer_first_item_social_links_wrapper {
        width: 160px!important;
    }
}

@media (max-width: 851px)  {
    .footer_first_item_info_box2_address_details_info {
        flex-direction: column;
        /*align-items: unset!important;*/
    }
    .footer_first_item_social_links_wrapper {
        width: 160px!important;
    }
    .footer_first_item {
        flex-wrap: wrap;
        padding: 25px 0!important;
    }
    .footer_first_item_info_box {
        width: 100%!important;
        margin-right: unset!important;
        margin-bottom: 20px;
    }
    .footer_first_item_logo_social_links_wrapper {
        width: 100% !important;
        margin-bottom: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .footer_first_item_info_box2 {
        width: 100% !important;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .footer_second_item {
        padding: 20px 0!important;
        flex-wrap: wrap;
    }
    .footer_second_item_info {
        margin-bottom: 20px!important;
        text-align: center;
    }
    .footer_first_item_info_box_main_info {
        text-align: center;
    }
    .footer_first_item_info_box_main_info2 {
        text-align: center;
    }
    .footer_first_item_info_box_title {
        text-align: center;
    }
        .footer_second_item_links_wrapper {
            margin: 0 auto;
        }
    }

/*.footer_first_item_social_link:first-child img {*/
/*    width: 25px;*/
/*    position: relative;*/
/*    top: -1px;*/
/*}*/

.footer_first_item_info_box2_address_details_info a {
    color: #ff6501!important;
    cursor: pointer;
    font-weight: 600!important;
    outline: none;
    padding-left: 5px;
    text-decoration: none;
    font-family: 'circular';
}

.top_item_info_social_link_svg_parent {
    width: 35px;
    height: 35px;
    background: #FF6501;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 2px;
}
