@import url('https://fonts.googleapis.com/css2?family=Raleway&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@700&display=swap');

*{
    margin: 0;
    padding:0;
    box-sizing: border-box;
}

.header{
    width: 100%;
}
.fixed {
    position: fixed;
    background: white;
    box-shadow: 0px 3px 10px -4px rgba(0, 0, 0, 0.5);
    z-index: 999999;
    width: 100%;
    top: 0
}

.header_wrapper {
    width: 100%;
    max-width: 1170px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 0;
}

.header_logo_navigation_links_wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 595px;
    width: 100%;
}

.headers_navigation_links_wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-left: 50px;
    flex: 1;
    margin-right: 62px;
}

.header_logo_link {
    cursor: pointer;
    outline: none;
    display: flex;
}
.header_logo_link img {
    width: 121px;
}
.headers_navigation_link {
    list-style: none;
    text-decoration: none;
    cursor: pointer;
    font-family: 'Raleway', sans-serif;
    font-size: 16px;
    font-weight: 500;
    color: #828282;
}

.headers_navigation_link:hover {
    color: #FF6501;
}

.ReactFlagsSelect-module_selectBtn__19wW7{
    font-family: 'Lato', sans-serif!important;
    border: unset!important;
    font-size: 15px!important;

}
.get_consultation_btn {
    cursor: pointer;
    outline: none;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #02a0c8;
    font-family: 'Lato', sans-serif;
    font-size: 16px;
    font-weight: 700;
    padding: 11px 18px;
    color: #ffffff;
    border-radius: 30px;
    animation-name: shake;
    animation-duration: 2s;
    animation-timing-function: ease-in-out;
    animation-delay: 1s;
    animation-iteration-count: infinite;
}
.send_get_consultation_btn{
    animation-name: shake;
    animation-duration: 2s;
    animation-timing-function: ease-in-out;
    animation-delay: 1s;
    animation-iteration-count: infinite;
}

.submit_your_application_info_item_btn, .join_our_team_info_item_btn{
    animation-name: shake;
    animation-duration: 2s;
    animation-timing-function: ease-in-out;
    animation-delay: 1s;
    animation-iteration-count: infinite;
}

.get_consultation_btn:hover {
    text-decoration: underline;
}

.ReactFlagsSelect-module_label__27pw9, .ReactFlagsSelect-module_secondaryLabel__37t1D{
    font-family: 'Lato', sans-serif!important;
    font-size: 15px!important;
}

.hamburger_menu {
    display: none;
}
/*media*/
@media (max-width: 1200px) {
    .header_wrapper {
        max-width: unset!important;
        width: 95% !important;
    }
}

@media (min-width: 648px) and (max-width: 951px) {
    .mobile_headers_navigation_links_wrapper {
        padding-top: 65px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 15px;
    }
    .header_logo_navigation_links_wrapper{
        max-width: unset!important;
        width: unset!important;
    }
    .header_menu--opener {
        display: block!important;
    }
    .header_mobile_menu_icon_btn_wrapper {
        display: flex!important;
        align-items: center;
        justify-content: space-between!important;
        width: 100% !important;
        max-width: 320px!important;
    }
    .headers_navigation_links_wrapper {
        display: none!important;
    }
    .header_mobile_wrapper {
        /*display: none;*/
        position: fixed; /* Stay in place */
        z-index: 999999; /* Sit on top */
        left: 0;
        /*right: -1000px;*/
        /*transition: 0.5s;*/
        top: 0;
        width: 100%; /* Full width */
        height: 100%; /* Full height */
        overflow: auto; /* Enable scroll if needed */
        background:  rgba(23, 23, 23, 0.85);
    }
    .header_mobile_wrapper_child {
        height: 100%;
        width: 300px;
        position: absolute;
        z-index: 99;
        right: 0;
        top: 0;
        padding-top: 25px;
        padding-left: 10px;
        overflow: scroll;
        padding-right: 10px;
        background: #ffffff;
        box-shadow: 0px 3px 10px -4px rgba(0 0 0, 15);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;


    }

    .header_mobile_wrapper_close_btn {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        background: none;
        /*border: 1px #6DDCFF solid;*/
        /*border-radius: 60px;*/
        border: none!important;
        width: 46px;
        height: 36px;
        position: absolute;
        right: 15px;
        top: 25px;
    }
    .header_menu--opener {
        /*display: none;*/
        height: 51px;
        width: 51px;
        /*overflow: hidden;*/
        cursor: pointer;
        background: none;
        border: none;

    }
    .line {
        display: block;
        height: 3px;
        background: #FF6501;
        border-radius: 10%;
        transform: rotateZ(0);
        transition: .4s ease all;
    }

    .line_one {
        width: 46px;
        margin-bottom: 12px;
        position: relative;
        right: -4px;
    }
    .line_two {
        width: 35px;
        position: relative;
        right: -16px;
        margin-bottom: 12px;

    }
    .line_three {
        width: 51px;
    }

}
@media (min-width: 571px) and (max-width: 648px) {
    .header_menu--opener {
        display: block!important;
    }
    .mobile_headers_navigation_links_wrapper {
        padding-top: 65px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 15px;
    }
    .header_logo_navigation_links_wrapper{
        max-width: unset!important;
        width: unset!important;
    }
    .header_mobile_menu_icon_btn_wrapper {
        display: flex!important;
        align-items: center;
        justify-content: space-between!important;
        width: 100% !important;
        max-width: 320px!important;
    }
    .get_consultation_btn {
        padding: 13px 16px!important;
        font-size: 14px!important;
    }
    .headers_navigation_links_wrapper {
        display: none!important;
    }
    .header_mobile_wrapper {
        /*display: none;*/
        position: fixed; /* Stay in place */
        z-index: 999999; /* Sit on top */
        left: 0;
        /*right: -1000px;*/
        /*transition: 0.5s;*/
        top: 0;
        width: 100%; /* Full width */
        height: 100%; /* Full height */
        overflow: auto; /* Enable scroll if needed */
        background:  rgba(23, 23, 23, 0.85);
    }
    .header_mobile_wrapper_child {
        height: 100%;
        width: 300px;
        position: absolute;
        z-index: 99;
        right: 0;
        top: 0;
        padding-top: 25px;
        padding-left: 10px;
        overflow: scroll;
        padding-right: 10px;
        background: #ffffff;
        box-shadow: 0px 3px 10px -4px rgba(0 0 0, 15);

    }

    .header_mobile_wrapper_close_btn {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        background: none;
        /*border: 1px #6DDCFF solid;*/
        /*border-radius: 60px;*/
        border: none!important;
        width: 46px;
        height: 36px;
        position: absolute;
        right: 15px;
        top: 25px;
    }
    .header_menu--opener {
        display: block;
        height: 51px;
        width: 51px;
        /*overflow: hidden;*/
        cursor: pointer;
        background: none;
        border: none;

    }
    .line {
        display: block;
        height: 3px;
        background: #FF6501;
        border-radius: 10%;
        transform: rotateZ(0);
        transition: .4s ease all;
    }

    .line_one {
        width: 46px;
        margin-bottom: 8px;
        position: relative;
        right: -4px;
    }
    .line_two {
        width: 35px;
        position: relative;
        right: -16px;
        margin-bottom: 8px;

    }
    .line_three {
        width: 51px;
    }

}

@media (min-width: 511px) and (max-width: 571px) {
    .header_menu--opener {
        display: block!important;
    }
    .header_logo_navigation_links_wrapper{
        max-width: unset!important;
        width: unset!important;
    }
    .header_mobile_menu_icon_btn_wrapper {
        display: flex!important;
        align-items: center;
        justify-content: space-between!important;
        width: 100% !important;
        max-width: 305px!important;
    }
    .get_consultation_btn {
        padding: 13px 14px!important;
        font-size: 14px!important;
    }
    .headers_navigation_links_wrapper {
        display: none!important;
    }
    .header_mobile_wrapper {
        /*display: none;*/
        position: fixed; /* Stay in place */
        z-index: 999999; /* Sit on top */
        left: 0;
        /*right: -1000px;*/
        /*transition: 0.5s;*/
        top: 0;
        width: 100%; /* Full width */
        height: 100%; /* Full height */
        overflow: auto; /* Enable scroll if needed */
        background:  rgba(23, 23, 23, 0.85);
    }
    .header_mobile_wrapper_child {
        height: 100%;
        width: 300px;
        position: absolute;
        z-index: 99;
        right: 0;
        top: 0;
        padding-top: 25px;
        padding-left: 10px;
        overflow: scroll;
        padding-right: 10px;
        background: #ffffff;
        box-shadow: 0px 3px 10px -4px rgba(0 0 0, 15);
    }

    .header_mobile_wrapper_close_btn {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        background: none;
        /*border: 1px #6DDCFF solid;*/
        /*border-radius: 60px;*/
        border: none!important;
        width: 46px;
        height: 36px;
        position: absolute;
        right: 15px;
        top: 25px;
    }
    .header_menu--opener {
        display: block;
        height: 51px;
        width: 51px;
        /*overflow: hidden;*/
        cursor: pointer;
        background: none;
        border: none;

    }
    .line {
        display: block;
        height: 3px;
        background: #FF6501;
        border-radius: 10%;
        transform: rotateZ(0);
        transition: .4s ease all;
    }

    .line_one {
        width: 46px;
        margin-bottom: 8px;
        position: relative;
        right: -4px;
    }
    .line_two {
        width: 35px;
        position: relative;
        right: -16px;
        margin-bottom: 8px;

    }
    .line_three {
        width: 51px;
    }
    .header_logo_link img {
        width: 160px!important;
        height: 65px!important;
    }
    .mobile_headers_navigation_links_wrapper {
        padding-top: 65px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 15px;
    }
}
@media (min-width: 426px) and (max-width: 511px) {
    .header_menu--opener {
        display: block!important;
    }
    .header_logo_navigation_links_wrapper{
        max-width: unset!important;
        width: unset!important;
    }
    .header_mobile_menu_icon_btn_wrapper {
        display: flex!important;
        align-items: flex-end!important;
        justify-content: flex-end!important;
    }
    .get_consultation_btn {
        padding: 13px 32px!important;
        font-size: 16px!important;
    }
    .headers_navigation_links_wrapper {
        display: none!important;
    }
    .header_mobile_wrapper {
        /*display: none;*/
        position: fixed; /* Stay in place */
        z-index: 999999; /* Sit on top */
        left: 0;
        /*right: -1000px;*/
        /*transition: 0.5s;*/
        top: 0;
        width: 100%; /* Full width */
        height: 100%; /* Full height */
        overflow: auto; /* Enable scroll if needed */
        background:  rgba(23, 23, 23, 0.85);
    }
    .header_mobile_wrapper_child {
        height: 100%;
        width: 300px;
        position: absolute;
        z-index: 99;
        right: 0;
        top: 0;
        padding-top: 25px;
        padding-left: 10px;
        overflow: scroll;
        padding-right: 10px;
        background: #ffffff;
        box-shadow: 0px 3px 10px -4px rgba(0 0 0, 15);

    }

    .header_mobile_wrapper_close_btn {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        background: none;
        /*border: 1px #6DDCFF solid;*/
        /*border-radius: 60px;*/
        border: none!important;
        width: 46px;
        height: 36px;
        position: absolute;
        right: 15px;
        top: 25px;
    }
    .header_menu--opener {
        display: block;
        height: 51px;
        width: 51px;
        /*overflow: hidden;*/
        cursor: pointer;
        background: none;
        border: none;

    }
    .line {
        display: block;
        height: 3px;
        background: #FF6501;
        border-radius: 10%;
        transform: rotateZ(0);
        transition: .4s ease all;
    }

    .line_one {
        width: 46px;
        margin-bottom: 8px;
        position: relative;
        right: -4px;
    }
    .line_two {
        width: 35px;
        position: relative;
        right: -16px;
        margin-bottom: 8px;

    }
    .line_three {
        width: 51px;
    }
    .header_logo_link img {
        width: 160px!important;
        height: 65px!important;
    }
    .get_consultation_btn {
        display: none!important;
    }
    .mobile_get_consultation_btn {
        cursor: pointer;
        outline: none;
        border: none;
        display: flex!important;
        justify-content: center;
        align-items: center;
        background: #0092e4;
        font-family: 'Lato', sans-serif;
        font-size: 16px;
        font-weight: 700;
        padding: 13px 28px;
        color: #ffffff;
        border-radius: 30px;
        align-self: center;
        margin: 0 auto;
        /*margin-bottom: 30px;*/

    }
    .mobile_headers_navigation_links_wrapper {
        padding-top: 65px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 15px;
    }
}
@media  (max-width: 425px) {
    .header_menu--opener {
        display: block!important;
    }
    .header_logo_navigation_links_wrapper{
        max-width: unset!important;
        width: unset!important;
    }
    .header_mobile_menu_icon_btn_wrapper {
        display: flex!important;
        align-items: flex-end!important;
        justify-content: flex-end!important;
    }
    .get_consultation_btn {
        padding: 13px 32px!important;
        font-size: 16px!important;
    }
    .headers_navigation_links_wrapper {
        display: none!important;
    }
    .header_mobile_wrapper {
        /*display: none;*/
        position: fixed; /* Stay in place */
        z-index: 999999; /* Sit on top */
        left: 0;
        /*right: -1000px;*/
        /*transition: 0.5s;*/
        top: 0;
        width: 100%; /* Full width */
        height: 100%; /* Full height */
        overflow: auto; /* Enable scroll if needed */
        background:  rgba(23, 23, 23, 0.85);
    }
    .header_mobile_wrapper_child {
        height: 100%;
        width: 300px;
        position: absolute;
        z-index: 99;
        right: 0;
        top: 0;
        padding-top: 25px;
        padding-left: 10px;
        overflow: scroll;
        padding-right: 10px;
        background: #ffffff;
        box-shadow: 0px 3px 10px -4px rgba(0 0 0, 15);

    }

    .header_mobile_wrapper_close_btn {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        background: none;
        /*border: 1px #6DDCFF solid;*/
        /*border-radius: 60px;*/
        border: none!important;
        width: 46px;
        height: 36px;
        position: absolute;
        right: 15px;
        top: 25px;
    }
    .header_menu--opener {
        display: block;
        height: 51px;
        width: 51px;
        /*overflow: hidden;*/
        cursor: pointer;
        background: none;
        border: none;

    }
    .line {
        display: block;
        height: 3px;
        background: #FF6501;
        border-radius: 10%;
        transform: rotateZ(0);
        transition: .4s ease all;
    }

    .line_one {
        width: 46px;
        margin-bottom: 8px;
        position: relative;
        right: -4px;
    }
    .line_two {
        width: 35px;
        position: relative;
        right: -16px;
        margin-bottom: 8px;

    }
    .line_three {
        width: 51px;
    }
    .header_logo_link img {
        width: 150px!important;
        height: 60px!important;
    }
    .get_consultation_btn {
        display: none!important;
    }
    .mobile_get_consultation_btn {
        cursor: pointer;
        outline: none;
        border: none;
        display: flex!important;
        justify-content: center;
        align-items: center;
        background: #0092e4;
        font-family: 'Lato', sans-serif;
        font-size: 16px;
        font-weight: 700;
        padding: 13px 28px;
        color: #ffffff;
        border-radius: 30px;
        align-self: center;
        margin: 0 auto;
    }
    .mobile_headers_navigation_links_wrapper {
        padding-top: 65px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 15px;
    }
}

.header_mobile_menu_icon_btn_wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    width: 100%;
    max-width: 480px;
}

.mobile_get_consultation_btn {
    display: none;
}

@media (max-width: 950px) {
    .headers_navigation_link {
        margin-bottom: 20px;
    }
}

.container1 {
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    margin-right: 9px;
    margin-bottom: 15px;
    width: 30%;
}

/* Hide the browser's default checkbox */
.container1 input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.checkmark1 {
    position: relative;
    height: 20px;
    width: 20px;
    border: 1px solid #FF6501;
    border-radius: 4px;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
}


/* Create the checkmark/indicator (hidden when not checked) */
.checkmark1:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.container1 input:checked ~ .checkmark1:after {
    display: block
}

/* Style the checkmark/indicator */
.container1 .checkmark1:after {
    content: '';
    background-image: url("../svg/check_icon.svg");
    background-repeat: no-repeat;
    background-position: center;
    width: 20px;
    height: 20px;
    left: 50%;
    top: 5%;
    transform: translate(-50%, -7%);
    background-color: #FF6501;
    border-radius: 3px;
    /*display: none;*/
}



.container2 {
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    margin-right: 9px;
    width: 64.5%;
    margin-bottom: 12px;
}

/* Hide the browser's default checkbox */
.container2 input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.checkmark2 {
    position: relative;
    height: 20px;
    width: 20px;
    border: 1px solid #FF6501;
    border-radius: 4px;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
}


/* Create the checkmark/indicator (hidden when not checked) */
.checkmark2:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.container2 input:checked ~ .checkmark2:after {
    display: block
}

/* Style the checkmark/indicator */
.container2 .checkmark2:after {
    content: '';
    background-image: url("../svg/check_icon.svg");
    background-repeat: no-repeat;
    background-position: center;
    width: 20px;
    height: 20px;
    left: 50%;
    top: 5%;
    transform: translate(-50%, -7%);
    background-color: #FF6501;
    border-radius: 3px;
    /*display: none;*/
}


.container3 {
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    margin-right: 9px;
    width: 30%;
}

/* Hide the browser's default checkbox */
.container3 input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.checkmark3 {
    position: relative;
    height: 20px;
    width: 20px;
    border: 1px solid #FF6501;
    border-radius: 4px;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
}


/* Create the checkmark/indicator (hidden when not checked) */
.checkmark3:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.container3 input:checked ~ .checkmark3:after {
    display: block
}

/* Style the checkmark/indicator */
.container3 .checkmark3:after {
    content: '';
    background-image: url("../svg/check_icon.svg");
    background-repeat: no-repeat;
    background-position: center;
    width: 20px;
    height: 20px;
    left: 50%;
    top: 5%;
    transform: translate(-50%, -7%);
    background-color: #FF6501;
    border-radius: 3px;
    /*display: none;*/
}


.container4 {
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    margin-right: 9px;
    width: 30%;
}

/* Hide the browser's default checkbox */
.container4 input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.checkmark4 {
    position: relative;
    height: 20px;
    width: 20px;
    border: 1px solid #FF6501;
    border-radius: 4px;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
}


/* Create the checkmark/indicator (hidden when not checked) */
.checkmark4:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.container4 input:checked ~ .checkmark4:after {
    display: block
}

/* Style the checkmark/indicator */
.container4 .checkmark4:after {
    content: '';
    background-image: url("../svg/check_icon.svg");
    background-repeat: no-repeat;
    background-position: center;
    width: 20px;
    height: 20px;
    left: 50%;
    top: 5%;
    transform: translate(-50%, -7%);
    background-color: #FF6501;
    border-radius: 3px;
    /*display: none;*/
}


.container5 {
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    margin-right: 9px;
    width: 30%;
}

/* Hide the browser's default checkbox */
.container5 input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.checkmark5 {
    position: relative;
    height: 20px;
    width: 20px;
    border: 1px solid #FF6501;
    border-radius: 4px;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
}


/* Create the checkmark/indicator (hidden when not checked) */
.checkmark5:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.container5 input:checked ~ .checkmark5:after {
    display: block
}

/* Style the checkmark/indicator */
.container5 .checkmark5:after {
    content: '';
    background-image: url("../svg/check_icon.svg");
    background-repeat: no-repeat;
    background-position: center;
    width: 20px;
    height: 20px;
    left: 50%;
    top: 5%;
    transform: translate(-50%, -7%);
    background-color: #FF6501;
    border-radius: 3px;
    /*display: none;*/
}

.show_get_consultation_popup_check_input_title {
    font-family: 'Raleway', sans-serif;
    font-size: 14px;
    font-weight: 500;
    color: #1E272E;
    flex: 1;
}

.show_get_consultation_popup {
    /*display: none;*/
    position: fixed; /* Stay in place */
    z-index: 999999; /* Sit on top */
    left: 0;
    /*right: -1000px;*/
    /*transition: 0.5s;*/
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background: rgba(40, 40, 56, 0.76);
    backdrop-filter: blur(2px);
    /*padding-top: 100px;*/
    /*padding-bottom: 100px;*/
    /*display: flex;*/
    /*justify-content: center;*/
    /*align-items: center;*/
    /*padding-top: 100px;*/
    /*padding-bottom: 50px;*/
}

.show_get_consultation_popup_wrapper {

    overflow: scroll;
    overflow-x: hidden;
    overflow-y: hidden;
    height: 100%;
    width: 100%;
    padding: 30px 30px 0px 30px;

}
.show_get_consultation_popup_wrapper_parent {
    background: #FFFFFF;
    box-shadow: 0px 4px 4px 1px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    width: 100%;
    max-width: 778px;
    position: relative;
    margin: 50px auto;
    overflow: hidden;

}
.show_get_consultation_popup_input_field {
    /*border: 1px solid #17171F;*/
    border-top: unset;
    border-left: unset;
    border-right: unset;
    width: 100%;
    padding-bottom: 5px;
    padding-left: 5px;
    font-family: 'Raleway', sans-serif;
    font-size: 16px;
    font-weight: 500;
    color: #1E272E;
    outline: none;
}
.show_get_consultation_popup_input_field::placeholder {
    font-family: 'Raleway', sans-serif;
    font-size: 16px;
    font-weight: 500;
    color: #1E272E;
}

.show_get_consultation_popup_input_field_wrapper {
    width: 100%;
    margin-bottom: 10px;
    position: relative;
}

.show_get_consultation_popup_checkbox_inputs_wrapper {
    width: 100%;
    margin-top:40px;
}

.show_get_consultation_popup_check_input_title_main {
    font-family: 'Raleway', sans-serif;
    font-size: 18px;
    font-weight: 500;
    color: #1E272E;
    margin-bottom: 20px;
}

.show_get_consultation_popup_checkbox_inputs_wrapper_parent {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.send_get_consultation_btn {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Lato", sans-serif;
    font-size: 16px;
    font-weight: 700;
    padding: 13px 28px;
    color: #ffffff;
    border: 1px solid #fff;
    border-radius: 30px;
    box-sizing: border-box;
    overflow: hidden;
    outline: 0;
    cursor: pointer;
    background: #ff6a38;
    margin-top: 48px;
}

.show_get_consultation_popup_logo {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
}
.show_get_consultation_popup_close_btn {
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;
    outline: none;
    border: none;
    background: none;
    z-index: 9;
}

@media (max-width: 820px) {
    .show_get_consultation_popup_wrapper{
        max-width: unset!important;
        width: 95% !important;
    }
}

/*@media (min-width: 426px) and (max-width: 768px) {*/
/*    .show_get_consultation_popup_wrapper{*/
/*        padding: 60px 30px 70px 30px!important;*/
/*    }*/
/*}*/
@media (max-width: 425px) {
    .show_get_consultation_popup_wrapper{
        padding: 20px 20px 0 20px!important;
    }
}

@media (max-width: 540px) {
    .container1 {
        width: 100% !important
    }
    .container2 {
        width: 100% !important
    }
    .container3 {
        width: 100% !important;
        margin-bottom: 15px;
    }
    .container4 {
        width: 100% !important;
        margin-bottom: 15px;
    }
    .container5 {
        width: 100% !important;
        margin-bottom: 15px;
    }
}


.footer_first_item_social_links_wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 186px;
}


.footer_first_item_social_link {
    width: 40px;
    height: 40px;
    display: flex;
    text-decoration: none;
    cursor: pointer;
    outline: none;
}
.footer_first_item_social_link:first-child {
    width: 35px;
    height: 35px;
}

.footer_first_item_social_link:last-child img  {
    width: 34px;
    position: relative;
    top: 3px;
    height: 40px;
}



.footer_first_item_social_link:nth-child(2) img  {
    width: 40px;
    height: 40px;
}


.mobile_get_consultation_btn_social_links_wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.mobile_get_consultation_btn {
    margin-top: 30px;
}

.header_mobile_wrapper_child {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    padding-bottom: 50px;
}

.header_menu--opener {
    display: none;
}

@media (max-width: 510px) {
    .mobile_get_consultation_btn {
        display: flex!important;

    }
    .mobile_get_consultation_btn {
        cursor: pointer;
        outline: none;
        border: none;
        display: flex!important;
        justify-content: center;
        align-items: center;
        background: #0092e4;
        font-family: 'Lato', sans-serif;
        font-size: 16px;
        font-weight: 700;
        padding: 13px 28px;
        color: #ffffff;
        border-radius: 30px;
        align-self: center;
        margin: 0 auto;
        margin-top: 30px;

    }
}


@keyframes shake {
    0% {
        transform: none;
    }

    3% {
        transform: scale(1.02, 0.97);
    }

    9% {
        transform: scale(0.95, 1.05);
    }

    15% {
        transform: scale(1.05, 0.95);
    }

    21% {
        transform: scale(0.95, 1.05);
    }

    27% {
        transform: scale(1.02, 0.97);
    }

    30% {
        transform: none;
    }

    100% {
        transform: none;
    }
}

.show_get_consultation_popup_body_wrapper {
    display: flex;
}


.show_get_consultation_popup_body_wrapper_right {
    width: 55%;
    height: 100%;
}

.show_get_consultation_popup_checkbox_inputs_wrapper_parent label {
    margin-bottom: 15px;
    width: 100%;
}

.show_get_consultation_popup_input_field::placeholder{
    color: #828282;
}

.show_get_consultation_popup_input_field{
    border-bottom: 1px solid #82828269;
    padding-left: 50px;
    height: 46px;
}

.show_get_consultation_popup_body {
    padding: 48px 0;
}

.show_get_consultation_popup_body_title {
    margin-bottom: 34px;
    font-family: 'Raleway', sans-serif;
    font-size: 27px;
    font-weight: bold;
    color: #575757;
}

.form_icon_wrapper {

    width: 30px;
    height: 30px;
    background: #ff6a38;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 6px;
}

.form_icon_wrapper svg {
    width: 20px;
    height: 20px;
}
.form_icon_wrapper svg g{
    fill: white;
}

.form_icon_wrapper_email svg {
    width: 15px;
    height: 15px;
}
.form_icon_wrapper_email svg path{
    fill: white;
}

@media (min-width: 1026px) and (max-width: 1080px) {
    .headers_navigation_links_wrapper {
        padding-left: 30px!important;
        margin-right: 30px!important;
    }
}
@media (min-width: 951px) and (max-width: 1026px) {
    .headers_navigation_links_wrapper {
        padding-left: 20px!important;
        margin-right: 20px!important;
    }
    .headers_navigation_link {
        font-size: 14px!important;
    }
    .get_consultation_btn {
        font-size: 14px!important;
        padding: 11px!important;
        }
        .top_phone_link {
            padding: 11px 14px!important;
            font-size: 14px!important;
        }
    }

@media (max-width: 951px) {
      #desktop_top_phone_link {
          display: none!important;
      }
        #mobile_top_phone_link {
            display: flex!important;
            margin-right: unset!important;
            margin-top: 20px!important;
        }
}


#mobile_get_consultation_btn {
    display: none;
}

@media (max-width: 511px) {
    #desktop_get_consultation_btn {
        display: none!important;
    }
    #mobile_get_consultation_btn {
        display: flex!important;
        margin-top: 20px;
    }
}

